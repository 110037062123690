import './TokenExpireScreen.scss';

import { memo } from "react";

import Logo from '../../../../components/Header/Logo';

const TokenExpireScreen = () => {
  return (
    <div className='token-expire-screen'>
      <Logo />
      <div className="container">
        <p>
          L'accès à cette page a expiré
        </p>
      </div>
    </div>
  );
};

export default memo(TokenExpireScreen);
