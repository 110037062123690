import './OneLineSwitch.scss';

import { memo } from 'react';
import Switch from 'react-switch';

import { COLOR } from '../../constants/Constant';

interface OneLineSwitchInterface {
  label: string;
  describe: string;
  image: string;
  checked: boolean;
  onChange: () => void;
}

const OneLineSwitch = ({
  label,
  describe,
  image,
  checked,
  onChange = () => {},
}: OneLineSwitchInterface) => {
  return (
    <div className='one-line-switch'>
      <div className='left-part'>
        <div className="image-container">
          <img src={image}/>
        </div>
        <div className='details'>
          <p className='label'>{label}</p>
          <p className='describe'>{describe}</p>
        </div>
      </div>
      <div className="right-part">
        <Switch
          width={50}
          checkedIcon={false}
          uncheckedIcon={false}
          checked={checked}
          onColor={COLOR.GREEN}
          offColor={COLOR.GREY}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default memo(OneLineSwitch);
