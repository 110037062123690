import { NavigateFunction } from 'react-router-dom';
import { io } from 'socket.io-client';
import { CallStatusType } from 'src/interfaces/model/CallInterface';

// import { useNavigation } from './../hooks/NavigationService'
import { VisitorUserInterface } from '../interfaces/model/UserInterface';
import { API, CALL_STATUS, SOCKET } from './../constants/Constant';
import Http from './Http';

const { START, MISS, JOIN, WAIT, ABORT, DECLINE } = CALL_STATUS;

const IO = io(API.BASE_URL, { autoConnect: false });

class Socket {
  io = IO;

  connectUser = (email: string) => {
    const status = this.status();
    if (status) return;
    console.log('connect user', email);
    IO.connect();
    IO.emit(SOCKET.USER_CONNECTED, { email });
  };

  disconnectUser = (email: string) => {
    console.log('disconnect user', email);
    IO.emit(SOCKET.USER_DISCONNECTED, { email });
    IO.removeAllListeners();
  };

  send = async (name: string, object: any, usersEmail?: Array<string>, emitBackendEvent = true) => {
    try {
      emitBackendEvent && IO.emit(name, object);
      await Http.post('sockets/users', { name, object, usersEmail: usersEmail?.filter(u => !!u) });
    } catch (err) {
      console.log('Socket send', err);
    }
  };

  status = () => {
    return IO.connected;
  };

  startListener = ({ visitorUser, navigate, setShowReceiverCamera, setCallStatus, disconnectCall, updateReceiverDetails, setCancelButtonIsVisible }: {
    visitorUser: VisitorUserInterface,
    navigate: NavigateFunction,
    setShowReceiverCamera: (showReceiverCamera: boolean) => void,
    setCallStatus: (callStatus: CallStatusType) => void,
    disconnectCall: (navigate: NavigateFunction, callName: string, visitorUser: VisitorUserInterface) => void,
    updateReceiverDetails: (listnerId: string) => void,
    setCancelButtonIsVisible: (isVisible: boolean) => void
  }) => {
    console.log('🧦 init Start Call Listener Socket');
    setCallStatus(START);

    IO.on(SOCKET.CONNECT, () => {
      console.log('🧦 Socket connected');
    });

    IO.on(SOCKET.USER_AVAILABLE, () => {
      console.log("🧦 USER_AVAILABLE", WAIT);
      setCallStatus(WAIT);
      // TODO: handel receiver connected socket
    });

    IO.once(SOCKET.ACCEPT_CALL, ({ callId, listenerId }: any) => {
      console.log('🧦 ACCEPT_CALL', JOIN, callId);
      updateReceiverDetails(listenerId);
      setCallStatus(JOIN);
      setCancelButtonIsVisible(false);
      setTimeout(() => {
        setCancelButtonIsVisible(true);
      }, 10000);
    });

    IO.once(SOCKET.RECEIVER_READY, ({ callId }) => {
      console.log('🧦 RECEIVER_READY', callId);
      navigate('/VideoCallScreen');
    });

    IO.on(SOCKET.SHOW_RECEIVER_CAMERA, ({ isEnabled, callId }) => {
      console.log('🧦 SHOW_RECEIVER_CAMERA', isEnabled, callId);
      setShowReceiverCamera(isEnabled);
    });

    IO.on(SOCKET.CANCEL_CALL, ({ callId }: any) => {
      // TODO: handle socket for call cancel
      console.log('🧦 CANCEL_CALL', MISS, callId);
      setCallStatus(MISS);
      disconnectCall(navigate, callId, visitorUser);
    });

    IO.on(SOCKET.DECLINE_CALL, ({ callId }: any) => {
      console.log('🧦 DECLINE_CALL', DECLINE, callId);
      setCallStatus(DECLINE);
      disconnectCall(navigate, callId, visitorUser);
    });

    IO.on(SOCKET.ABORT_CALL, ({ callId }) => {
      console.log('🧦 ABORT_CALL', ABORT, callId);
      setCallStatus(ABORT);
      disconnectCall(navigate, callId, visitorUser);
    });
  };

  on = (event: string, cb: any) => {
    IO.on(event, (payload: any) => cb(payload));
  };
}

export default new Socket();
