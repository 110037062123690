import { pick } from "lodash";
import { createContext, useContext, useRef, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { CallStatusType } from 'src/interfaces/model/CallInterface';
import { LocalTrack } from 'twilio-video';

import { CALL_STATUS } from '../constants/Constant';
import { VisitorUserInterface } from '../interfaces/model/UserInterface';
import Socket from './../services/Socket';
import { useEffectOnce } from './UseEffectOnce';

const { START } = CALL_STATUS;

type CallContextType = {
  room: any,
  receiver: any,
  callName: string,
  showReceiverCamera: boolean,
  callHousing: any,
  visitorUser?: VisitorUserInterface,
  twilioToken: string,
  tracks: any[],
  callStatus: CallStatusType,
  debugMode: boolean,
  internetIsOnline: boolean,
  cancelButtonIsVisible: boolean,
  disconnectCall: (navigate: NavigateFunction, callName: string, visitorUser: VisitorUserInterface) => void,
  setRoom: (room: any) => void,
  setReceiver: (receiver?: any) => void,
  setCallHousing: (housing?: any) => void,
  setCallName: (callName: string) => void,
  setShowReceiverCamera: (showReceiverCamera: boolean) => void,
  setCallStatus: (callStatus: CallStatusType) => void,
  setDebugMode: (debugMode: boolean) => void,
  disconnectSocket: (callName: string, visitorUser: VisitorUserInterface) => void,
  setTwilioToken: (twilioToken: string) => void,
  setTracks: (tracks: any[]) => void,
  updateReceiverDetails: (listenerId: string) => void,
  setSocket: (navigate: NavigateFunction, callName: string, visitorUser: VisitorUserInterface) => void,
}

const defaultCallValue: any = {
  callName: '',
  showReceiverCamera: false,
  receiver: {},
  twilioToken: '',
  tracks: [],
  callHousing: {},
  callStatus: START,
  debugMode: false,
  internetIsOnline: true,
  cancelButtonIsVisible: true,
  disconnectCall: () => true,
  setRoom: (room: any) => room,
  setTwilioToken: (token: string) => token,
  setTracks: (tracks: any[]) => tracks,
  setReceiver: (receiver?: any) => receiver,
  setCallHousing: (housing?: any) => housing,
  disconnectSocket: () => true,
  setCallName: (callName: string) => callName,
  setSocket: () => true,
  setShowReceiverCamera: (showReceiverCamera: boolean) => showReceiverCamera,
  setCallStatus: (callStatus: CallStatusType) => callStatus,
  setDebugMode: (debugMode: boolean) => debugMode,
  updateReceiverDetails: (listenerId: string) => listenerId
};

export const CallContext = createContext<CallContextType>(defaultCallValue);

export const useCall = () => {
  return useContext(CallContext);
};

interface CallServiceProps {
  children: any
}

const CallService = ({ children }: CallServiceProps) => {

  const canDisconnectRef = useRef<boolean>(false);
  const [room, setRoom] = useState<any>();
  const [receiver, setReceiver] = useState<any>({});
  const [onlinestatus, setOnlineStatus] = useState(true);
  const [twilioToken, setTwilioToken] = useState('');
  const [tracks, setTracks] = useState<LocalTrack[]>([]);
  const [callHousing, setCallHousing] = useState<any>({});
  const [callName, setCallName] = useState<string>('');
  const [visitorUser, setVisitorUser] = useState<VisitorUserInterface>();
  const [showReceiverCamera, setShowReceiverCamera] = useState<boolean>(false);
  const [callStatus, setCallStatus] = useState<CallStatusType>('');
  const [debugMode, setDebugMode] = useState<boolean>(false);
  const [cancelButtonIsVisible, setCancelButtonIsVisible] = useState<boolean>(true);
  
  useEffectOnce(() => {
    window.ononline = () => {
      setOnlineStatus(true);
    };

    window.onoffline = () => {
      setOnlineStatus(false);
    };
  });

  const disconnectCall = (navigate: NavigateFunction, _callName: string, _visitorUser: VisitorUserInterface) => {
    if (canDisconnectRef.current) return;
    canDisconnectRef.current = true;
    setRoom((prevRoom: any) => {
      if (prevRoom) prevRoom.disconnect();
      return null;
    });
    setTwilioToken('');
    console.log('clear tracks');
    tracks.forEach((track: any) => track?.stop());
    setTracks([]);
    setCallName('');
    navigate('/EndCallScreen');
    setTimeout(() => {
      canDisconnectRef.current = false;
      disconnectSocket(_callName, _visitorUser);
    }, 3000);
  };

  const updateReceiverDetails = (listenerId?: string) => {

    const isHousingCall = receiver?.isGroupedCall;

    const receiverDetails = isHousingCall ? callHousing?.members?.filter((member: any) => member.participantId === listenerId) : [];
    const receiverIsSame = receiver?.participantId === receiverDetails[0]?.participantId;

    if (!receiverIsSame && isHousingCall && receiverDetails?.length) {
      setReceiver({
        ...receiverDetails[0],
        isGroupedCall: callHousing?.isGroupedCall
      });
    }
  };

  const setSocket = (navigate: NavigateFunction, _callName: string, _visitorUser: VisitorUserInterface) => {

    if (!_callName) return;

    const user = Object.keys(_visitorUser).length ? _visitorUser : getCallAnonymousUser(_callName);
    const _visitorUserPick = pick(user, ['fullName', 'email', 'avatar']);
    setVisitorUser(_visitorUserPick);
    Socket.startListener({ visitorUser: _visitorUserPick, navigate, setShowReceiverCamera, setCallStatus, disconnectCall, updateReceiverDetails, setCancelButtonIsVisible });
    Socket.connectUser(user.email);
  };

  const disconnectSocket = (_callName: string, _visitorUser: VisitorUserInterface) => {
    setVisitorUser(undefined);
    const { email } = Object.keys(_visitorUser).length ? _visitorUser : getCallAnonymousUser(_callName);
    Socket.disconnectUser(email);
  };

  const getCallAnonymousUser = (callId: string) => ({
    fullName: 'Visiteur Anonyme',
    email: `anonymous_${callId}@cleancall.fr`,
    avatar: 'https://filecleancall01.s3.eu-west-3.amazonaws.com/development/anonymous_v2.jpeg',
  });

  return (
    <CallContext.Provider value={{
      room,
      setRoom,
      receiver,
      callName,
      showReceiverCamera,
      setSocket,
      twilioToken,
      tracks,
      callHousing,
      visitorUser,
      callStatus,
      debugMode,
      setReceiver,
      setCallName,
      setShowReceiverCamera,
      setCallStatus,
      setDebugMode,
      setTwilioToken,
      setTracks,
      setCallHousing,
      disconnectSocket,
      disconnectCall,
      cancelButtonIsVisible,
      updateReceiverDetails,
      internetIsOnline: onlinestatus
    }}>

      {children}
    </CallContext.Provider>
  );
};

export default CallService;
