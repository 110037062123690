import './CancelPaymentScreen.scss';

import { memo } from "react";

import CCLottie from '../../../../components/CCLottie';
import Logo from '../../../../components/Header/Logo';

const CancelPaymentScreen = () => {

  return (
    <div className='cancel-payment-screen'>
      <Logo />
      <div className="container">
        <div className="card">
          <CCLottie name='circle-error' loop={false}/>
          <h2>Le paiement a été annulé</h2>
        </div>
      </div>
    </div>
  );
};

export default memo(CancelPaymentScreen);
