import axios from 'axios';
import { toast } from 'react-toastify';

import Timeout, { TM } from '../utils/Timeout';
import { API, ENV } from './../constants/Constant';
import Utils from './../utils/Utils';

const DataApiInstance = axios.create({
  baseURL: API.HOST,
  timeout: 20000,
  headers: {
    'X-From': 'mobile_pwa'
  }
});

const logResponse = (res: any) => {
  const { status, config, data: response } = (res.status ? res : res.response);
  const { method, url, baseURL, headers, query, params, data: payload } = config;
  const icon = status < 300 ? '🟩' : (status < 400) ? '🟨' : (status < 500) ? '🟧' : '🟥';
  const fullUrl = `${baseURL}/${Utils.longSlice(url)}`;

  console.groupCollapsed(`${icon} ${status} ${method?.toUpperCase()} ${fullUrl}`);
  console.log({
    headers,
    ...(typeof (payload) === 'string' && { payload: JSON.parse(payload) }),
    ...(params && { params }),
    ...(query && { query }),
    ...(response && { response })
  });
  console.groupEnd();
};

DataApiInstance.interceptors.request.use(
  config => {
    Timeout.start(
      TM.REQUEST.GROUP_NAME,
      TM.REQUEST.INTERVAL_TIMEOUT_MESSAGES,
      TM.REQUEST.TIMEOUT_MESSAGE_FAILURE,
      5000,
      (msg: string) => toast.info(msg, { toastId: TM.REQUEST.GROUP_NAME, autoClose: 4000 }),
      (msg: string) => toast.error(msg, { toastId: TM.REQUEST.GROUP_NAME, autoClose: 4000 })
    );
    return config;
  });

DataApiInstance.interceptors.response.use(
  response => {
    Timeout.clearByGroupName(TM.REQUEST.GROUP_NAME);
    toast.dismiss(TM.REQUEST.GROUP_NAME);
    !ENV.IS_PROD && logResponse(response);
    return response;
  },
  async error => {
    Timeout.clearByGroupName(TM.REQUEST.GROUP_NAME);
    toast.dismiss(TM.REQUEST.GROUP_NAME);
    if (!error.response) {
      if(error.message === 'Network Error' || error.code === 'ECONNABORTED'){
        await Utils.wait(2000);
        toast.error(TM.REQUEST.TIMEOUT_MESSAGE_FAILURE, { toastId: TM.REQUEST.GROUP_NAME });
      }
    }else{
      !ENV.IS_PROD && logResponse(error);
    }

    return Promise.reject(error);
  }
);

let refreshToken: string | null;

class DataApi {
  refreshToken = refreshToken;

  instance = DataApiInstance;

  setAuthorisation = (accessT: string, refreshT: string) => {
    this.instance.defaults.headers.common.Authorization = `Bearer ${accessT}`;
    this.refreshToken = refreshT;
  };

  removeAuthorisation = () => {
    delete this.instance.defaults.headers.common.Authorization;
    this.refreshToken = null;
  };
}

export default new DataApi();
