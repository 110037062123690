const LOCAL = false;
export const BASE_URL = LOCAL ? 'http://localhost:3001' : process.env.REACT_APP_API_URL!;
export const FIREBASE = {
  API_URL: 'https://fcm.googleapis.com/fcm/send'
};
export const IS_LOCALHOST = window.location.origin.startsWith('https://localhost:3000') || window.location.origin.startsWith('https://192.168.');

export const ACTION_SHEET_DURATION = .5;

export const COLOR = {
  WHITE: '#FFFFFF',
  BLUE: '#00B2E4',
  DARK_BLUE: '#006B99',
  BLACK: '#051D3F',
  BLACK_05: 'rgba(5, 29, 63, .5)',
  RED: '#dd6262',
  ORANGE: '#FFA51D',
  GREEN: '#00CC96',
  PURPLE: '#D782FF',
  SAND: '#EFEFEF',
  DISABLE: '#EFF0F5',
  GREY: '#A1AFC3',
  GREY_LIGHT: '#F6F7FB',
  GREY_LIGHT_DARK: '#E0E0E1',
  GREY_DARK: '#686869',
  GREY_DARK_EXTRA: '#4a4848',
  TEXT: '#2D3142',
  SWITCH_INACTIVE: '#50717a',
  VIDEO_CALL_BG: '#453c3c',
  BLUE_FACEBOOK: '#165DEE',
  BLUE_LINKEDIN: '#0072b1',
  EXTRA_BLACK: '#000000',
  TEXT_GREY: '#7A7F83',
  TRANSPARENT: 'transparent',
  GRADIENT: {
    BLACK: ['#051D3F', '#072959'],
    RED: ['#FF5C5C', '#FF7575'],
    BLUE: ['#00B2E4', '#43d5fe'],
    ORANGE: ['#FF916A', '#FFA585'],
    GREEN: ['#00CC96', '#00E5A9'],
    PURPLE: ['#CB5CFF', '#D782FF'],
    DARK_BLUE: ['#006B99', '#1495CC']
  },
  REDDISH: '#FF5C5C',
  LIGHT_GREEN: '#6AD459',
  STAR_COLOR: '#FEA41D'
};

const ASSET_ROUTE = './assets/fonts/';

export const FONT: any = {
  SF_PRO: ASSET_ROUTE + 'SFPro-Regular',
  SF_PRO_DISPLAY: ASSET_ROUTE + 'SFProDisplay-Bold',
  NUNITO: ASSET_ROUTE + 'Nunito-Regular',
  BRANDON_GROTESQUE: ASSET_ROUTE + 'BrandonGrotesque-Regular',
  BRANDON_GROTESQUE_LIGHT: ASSET_ROUTE + 'BrandonGrotesque-Light',
  POPPINS_SEMI_BOLD: ASSET_ROUTE + 'Poppins-SemiBold',
  POPPINS_MEDIUM: ASSET_ROUTE + 'Poppins-Medium',
  OPEN_SANS: ASSET_ROUTE + 'OpenSans',
  POPPINS: ASSET_ROUTE + 'Poppins-Light',
  WEIGHT: {
    ULTRA_LIGHT: '100',
    REGULAR: '400',
    MEDIUM: '500',
    SEMI_BOLD: '700',
    BOLD: '800'
  }
};

export const BUTTON = {
  TYPE: {
    PLAIN: 'plain',
    CLEAR: 'clear',
    GRADIENT: 'gradient',
    REVERSE: 'reverse'
  }
};

export const KEY = {
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  FCM_TOKEN: 'FCM_TOKEN',
  USER_INIT: 'USER_INIT'
};

export const BOOL = {
  YES: 'yes',
  NO: 'no'
};

export const SOCKET = {
  USER_CONNECTED: 'user_connected',
  USER_DISCONNECTED: 'user_disconnected',
  CONNECT: 'connect',
  OFFER_CALL: 'offer_call',
  SHOW_RECEIVER_CAMERA: 'show_receiver_camera',
  CANCEL_CALL: 'cancel_call',
  ACCEPT_CALL: 'accept_call',
  CALL_ABORTED: 'call_aborted',
  DECLINE_CALL: 'decline_call',
  ANSWER_CALL: 'answer_call',
  ABORT_CALL: 'abort_call',
  USER_ALERT: 'user_alert',
  RECEIVER_READY: 'receiver_ready',
  USER_AVAILABLE: 'user_available',
  SCAN_QR_SUCCESS: 'scan_success'
};

export const API = {
  BASE_URL,
  HOST: `${BASE_URL}/api`
};

export const CALL_STATUS = {
  START: 'start',
  WAIT: 'wait',
  JOIN: 'join',
  MISS: 'miss',
  ABORT: 'abort',
  DECLINE: 'decline'
};

export const LAYOUT = {
  XSMALL: 4,
  SMALL: 8,
  MEDIUM: 12,
  REGULAR: 16,
  LARGE: 20,
  XLARGE: 24,
  XXLARGE: 48,
  XXXLARGE: 96
};

export const DEVICE = {
  SMALL: 667
};

export const CC_REGEX_URLS = [
  /^http(s)?:\/\/(m|app|api)\.([a-z0-9-_]+[.])*cleancall\.fr/,
  /^http(s)?:\/\/192\.168\.[0-9]*\.[0-9]*:3000/,
  /^http(s)?:\/\/localhost/,
];

export const APP_LINK = {
  ios: 'https://apps.apple.com/us/app/cleancall/id1597670491',
  android: 'https://play.google.com/store/apps/details?id=com.cleancall'
};

export const ENV = {
  IS_PROD: process.env.HOST === 'm.cleancall.fr',
  IS_DEV: process.env.HOST === 'm.develop.cleancall.fr',
  IS_LOCAL: process.env.HOST === 'localhost'
};

export const WEBSITE_URL = 'https://cleancall.fr';
export const DEFAULT_HOST = 'm.cleancall.fr';

export const FACEBOOK_APP_ID = '480242936285221';
export const LINKEDIN_CLIENT_ID = '78kko4u9d666zx';
export const APPLE_APP_ID = process.env.APPLE_APP_ID || 'com.cleancall.public.dev.client';
export const APPLE_REDIRECT_URL = `https://${process.env.HOST || DEFAULT_HOST}/`;
export const LINKEDIN_REDIRECT_URL = `https://${process.env.HOST || DEFAULT_HOST}/linkedin`;
export const GOOGLE_CLIENT_ID = '500667253541-nq3mdqlbki7b94p56h8339fiisq3hss0.apps.googleusercontent.com';
export const TERMS_OF_USE_URI = `${WEBSITE_URL}/charte-de-vie-privee`;
export const PRIVACY_POLICY_URI = `${WEBSITE_URL}/politique-de-confidentialite`;

export const STORAGE = {
  REFRESH_TOKEN: 'refresh_token'
};

export const BUY_HOUSING_STEPS = {
  CHOOSE_HOUSING: 'choose_housing',
  CHOOSE_STICKER: 'choose_sticker',
  CHOOSE_STICKER_TYPE: 'choose_sticker_type',
  CHOOSE_MEMBERS: 'choose_members',
  CREATE_HOUSING: 'create_housing',
  PAYMENT: 'payment'
};

export const SIZES = {
  XS: 480,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1600
};
