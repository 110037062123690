import './MoreMembersSuccess.scss';

import decode from "jwt-decode";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { useSearchParams } from 'react-router-dom';

import CCLottie from '../../../../../components/CCLottie';
import Logo from '../../../../../components/Header/Logo';
import OverlayLoader from '../../../../../components/OverlayLoader';
import { useEffectOnce } from '../../../../../hooks/UseEffectOnce';
import { BasicUserInterface } from '../../../../../interfaces/model/UserInterface';
import { getStripeSessionInfo } from '../../../../../services/CheckoutService';
import { validateToken } from '../../../../../services/TokenService';
import Analytics from '../../../../../utils/Analytics';
import Utils from '../../../../../utils/Utils';
import TokenExpireScreen from '../../TokenExpireScreen';

const MoreMembersSuccess = () => {

  const refAnimationInstance = useRef<any>(null);

  const [searchParams] = useSearchParams();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [tokenExpired, setTokenExpired] = useState<boolean>(false);
  const [user, setUser] = useState<BasicUserInterface>();

  useEffectOnce(() => {
    _checkQueryParams();
  });

  const _checkQueryParams = useCallback(async () => {
    try {
      await _checkSessionParams();
    } catch (err) {
      console.log(err);
    }

    setTimeout(() => setIsReady(true), 250);
  }, []);

  const _checkSessionParams = async () => {
    try {
      const token = searchParams.get('token')!;
      const sessionId = searchParams.get('session_id')!;

      if (!token || !sessionId) return setTokenExpired(true);
      try {
        await validateToken(token);
      } catch (error: any) {
        return setTokenExpired(true);
      }

      const _user = decode(token) as BasicUserInterface;
      const sessionInfo = await getStripeSessionInfo(sessionId);
      setUser(_user);

      Analytics.purchase(sessionInfo.user, {
        ...sessionInfo.order,
        productName: "membre supplémentaire"
      });

      return _user;
    } catch (e) {
      return undefined;
    }
  };

  const getInstance = useCallback((instance: any) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio: number, opts: any) => {
    refAnimationInstance?.current({
      ...opts,
      origin: { y: 0.7 },
      particleCount: Math.floor(200 * particleRatio)
    });
  }, []);

  const _fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);

  useEffect(() => {
    if(isReady && !tokenExpired && user?.email){
      _fire();
    }
  }, [isReady, tokenExpired, user]);

  if (!isReady) return <OverlayLoader visible={!isReady} showLogo={true} />;
  if (tokenExpired || !user?.email) return <TokenExpireScreen />;

  return (
    <>
      <div className='more-members-success'>
        <Logo />
        <div className="container">
          <div className="card">
            <CCLottie name='check-green' loop={false}/>
            <h2>🎉 Paiement effectué !</h2>
            <p>
              Félicitation { Utils.capitalize(user.firstName!) }, votre logement a agrandi sa capacité de membre supplémentaire 👏
            </p>
          </div>
        </div>
      </div>
      <ReactCanvasConfetti className='confetti' refConfetti={getInstance} />
    </>
  );
};

export default memo(MoreMembersSuccess);
