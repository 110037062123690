import './MemberSelectView.scss';

import { memo, useMemo } from 'react';

import ColumnCard, { ColumnCardInterface } from '../../../../components/ColumnCard';
import { getEquipmentTypeSentence, usePaymentProcess } from '../../../../hooks/PaymentProcessService';
import { member1, member5,member20 } from '../../../../services/Images';

export type SubscriptionModeType = 'monthly' | 'annually' | 'lifeTime';
export interface SubscriptionModeItemInterface {
  value: number,
  business?: boolean,
  originalPrice: number,
  reducedPrice: number,
}

export interface SubscriptionModeInterface {
  type: SubscriptionModeType;
  values: SubscriptionModeItemInterface[];
}

export const subscriptionModes: SubscriptionModeInterface[] = [
  {
    type: 'monthly',
    values: [
      {
        value: 1,
        originalPrice: 1.9,
        reducedPrice: 1.9,
      },
      {
        value: 1,
        business: true,
        originalPrice: 2.9,
        reducedPrice: 2.9,
      },
      {
        value: 5,
        originalPrice: 9.5,
        reducedPrice: 7.6,
      },
      {
        value: 5,
        business: true,
        originalPrice: 14.5,
        reducedPrice: 11.6,
      },
      // {
      //   value: 20,
      //   originalPrice: 38,
      //   reducedPrice: 28.5,
      // },
      {
        value: 20,
        business: true,
        originalPrice: 58,
        reducedPrice: 43.5,
      }
    ]
  },
  {
    type: 'annually',
    values: [
      {
        value: 1,
        originalPrice: 7.9,
        reducedPrice: 7.9,
      },
      {
        value: 5,
        originalPrice: 39.5,
        reducedPrice: 31.6,
      },
      // {
      //   value: 20,
      //   originalPrice: 158,
      //   reducedPrice: 118.5,
      // }
    ]
  },
  {
    type: 'lifeTime',
    values: [
      {
        value: 1,
        originalPrice: 24.9,
        reducedPrice: 24.9,
      },
      {
        value: 5,
        originalPrice: 124.5,
        reducedPrice: 99.6,
      },
      // {
      //   value: 20,
      //   originalPrice: 498,
      //   reducedPrice: 373.50,
      // }
    ]
  }
];

const cards: ColumnCardInterface[] = [
  {
    value: 1,
    title: '+1 Membre',
    describe: 'Ajouter un membre supplémentaire à votre {TYPE_LOGEMENT}.',
    image: member1
  },
  {
    value: 5,
    title: '+5 Membres',
    describe: "Ajouter 5 membres supplémentaires à votre {TYPE_LOGEMENT}.",
    image: member5
  },
  {
    value: 20,
    title: '+20 Membres',
    describe: 'Ajouter 20 membres supplémentaires à votre {TYPE_LOGEMENT}.',
    image: member20
  },
  // {
  //   value: 0,
  //   title: 'Non',
  //   hasSeparator: true
  // }
];

export const subscriptionModeValues = [
  { 
    label: 'Mensuel',
    value: 'monthly',
    text: '/ mois',
    isAvailable: true
  },
  { 
    label: 'Annuel',
    value: 'annually',
    text: '/ an',
    isAvailable: true
  },
  { 
    label: 'À VIE !! 🤩',
    value: 'lifeTime',
    text: 'à vie',
    isAvailable: true
  }
];

const MemberSelectView = () => {

  const { form, setForm } = usePaymentProcess();

  const _onSelectSubcriptionMode = (value: string) => {
    setForm((f: any) => ({
      ...f,
      subscriptionMode: value,
      // additionalMembersCount: undefined
    })); 
  };

  const _onSelectMember = (value: string) => setForm((f: any) => ({ ...f, additionalMembersCount: value }));

  const parsedCards = useMemo(() => {
    const selectedModeValues = (subscriptionModes.find(sub => sub.type === form.subscriptionMode) || subscriptionModes[0]).values;
    return cards.map((card: ColumnCardInterface) => {
      const typeLogement = getEquipmentTypeSentence(form.equipmentType, form.isBusiness);
      if(card.describe) card.describe = card.describe.replace('{TYPE_LOGEMENT}', typeLogement);
      const selectModeByValue = selectedModeValues.find(smv => smv.value === card.value && !!smv.business === form.isBusiness);
      const selectedModeValue = subscriptionModeValues.find(smv => smv.value === form.subscriptionMode);
      card.originalPrice = selectModeByValue?.originalPrice;
      card.reducedPrice = selectModeByValue?.reducedPrice;
      card.priceExtension = selectedModeValue?.text;
      return card;
    }).filter(card => !!card.reducedPrice);
  }, [form]);

  const selectedSubcription = subscriptionModeValues.find(v => v.value === form.subscriptionMode);

  return (
    <>
      {/* {
        !form.isBusiness && (
          <>
            <OneLineSelector
              values={subscriptionModeValues}
              selectedValue={form.subscriptionMode}
              onSelect={_onSelectSubcriptionMode} />

            <Height />
          </>
        )
      } */}

      <div className='column-card-row'>
        {!selectedSubcription?.isAvailable && <div className="no-available-bloc">
          <span className='text'>Cette offre sera bientôt disponible</span>
        </div>}
        {form.isBusiness && <div className="no-available-bloc-alone">
          <span className='text'>Veuillez nous contacter pour accédez à cette offre</span>
          <a className='link' href='mailto:hello@cleancall.fr'>Nous contacter</a>
        </div>}
        {parsedCards.map(({
          value, title, describe, image, originalPrice, reducedPrice, priceExtension, hasSeparator
        }: ColumnCardInterface, index: number) => (
          <ColumnCard
            key={index}
            value={value}
            title={title}
            image={image}
            originalPrice={originalPrice}
            reducedPrice={reducedPrice}
            priceExtension={priceExtension}
            describe={describe}
            selected={form.additionalMembersCount === value}
            hasSeparator={hasSeparator}
            onSelect={_onSelectMember}
          />
        ))}
      </div>
    </>
  );
};

export default memo(MemberSelectView);
