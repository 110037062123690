import pluralizeImport from 'pluralize-fr';

import styles from '../assets/scss/variable.scss';
import { COLOR } from '../constants/Constant';
import { EquipmentInterface } from '../interfaces/model/EquipmentInterface';

class UtilsClass {

  capitalize = (string: string) => {
    return string?.replace(/(?:^|\s)\S/g, (a) => {
      return a?.toUpperCase();
    });
  };

  uppercase = (string: string) => {
    return string ? string?.toUpperCase() : '';
  };
  getFullNameCaps = (firstName: string, lastName: string) => {
    const first = Utils.capitalize(firstName);
    const last = Utils.uppercase(lastName);
    return (`${first} ${last}`).trim();
  };

  longSlice = (string: string | null | undefined, count = 50) => {
    return (string && string?.length >= count + 3) ? `${string?.substring(0, count)}...` : string;
  };

  makeRandomString = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHJKMNPQRSTUVWXYZ123456789';
    const charactersLength = characters?.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  wait = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  toHexa = (r: number, g: number, b: number) => {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  };

  toRGB = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);
      return r + ',' + g + ',' + b;
    }
    return null;
  };

  getTransparent = (color: string, opacity = 1) => {
    return `rgba(${this.toRGB(color)}, ${opacity})`;
  };

  isEmpty = (obj: any): boolean => {
    return !!(obj.constructor === Object && !Object.keys(obj).length);
  };

  generateConstantFromScss = (stringkey: string) => {
    return Object.entries(styles)
      .filter(([key]) => key.startsWith(stringkey))
      .map(([key, value]) => ({ [key.replace(stringkey, '')]: value }));
  };
  isMultiHousing = (equipment: EquipmentInterface): boolean => {
    const { housings } = equipment;

    return housings?.length > 1;
  };
  repeat = async (count: number, cb: (index: number) => void): Promise<void> => {
    for (let i = 0; i < count; i++) {
      await cb(i);
    }
  };
  setVHProperty = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setDefaultBackgroundColor = () => {
    const isMobile = Utils.isMobile();
    const isBuyPage = window.location.pathname.includes('/buy');
    const bgColor = isMobile && !isBuyPage ? COLOR.BLACK :(isBuyPage) ? COLOR.WHITE : COLOR.GREY_LIGHT;
    document.documentElement.style.setProperty('--default-bg-color', bgColor);
    return bgColor;
  }
  isPortrait = () => {
    return !(window.orientation % 180);
  }

  /**
     * accorization
     * * get number items + word (plurialize if needed)
     * ? Example :
     * * - 1 mail send
     * * - 2 mails sends
     */
  accorization = (items: number | any[] | undefined, singularWord: string, showNumber?: boolean, messageOnEmpty?: string) => {
    let message = '';
    const isNumber = typeof items === 'number';
    const number = (
      isNumber ? items : items && items.length
    ) || 0;
    if (showNumber) {
      message += `${number} `;
    }
    if (number === 0 && messageOnEmpty) {
      message = messageOnEmpty;
    } else {
      message += number >= 2 ? pluralizeImport(singularWord) : singularWord;
    }

    return message;
  }

  plurialize = (singularWord: string) => {
    return pluralizeImport(singularWord);
  }

  validEmail = (email?: string) => {
    try {
      if (!email || !email?.length) throw { message: 'Veuillez saisir votre email' };
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const passRegex = re.test(email);
      if (!passRegex) throw { message: 'Veuillez saisir une adresse email valide' };

      return;
    } catch (error) {
      return error;
    }
  }

  validPassword = (password: string, confirmation?: string, updatePassword?: boolean) => {
    try {
      if (!password || !password?.length) throw { message: 'Veuillez saisir votre mot de passe' };
      if (password?.length < 8) throw { message: 'Votre mot de passe doit contenir au moins 8 caractères' };
      if (updatePassword) {
        const isUpperCase = !!/[A-Z]/.exec(password);
        const isNumeric = /\d/.test(password);
        if (!confirmation) throw { message: 'Vous devez confirmer votre nouveau mot de passe' };
        if (password !== confirmation) throw { message: 'Les mots de passe saisis ne sont pas identiques' };
        if (!isUpperCase) throw { message: 'Votre mot de passe doit contenir au moins une majuscule' };
        if (!isNumeric) throw { message: 'Votre mot de passe doit contenir au moins un chiffre' };
      }
      return;
    } catch (error) {
      return error;
    }
  }

  boolQuery = (queryKey?: string | null) => ((queryKey === '1' || queryKey === 'true') ? true : (queryKey === '0' || queryKey === 'false') ? false : undefined);

  isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

  popupCenter = (url: string, title: string, width: number, height: number) => {
    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 4;
    window.open(url, title, `resizable=yes,width=${width},height=${height},top=${top},left=${left}`);
  }
}

const Utils = new UtilsClass();
export default Utils;
