import AskCustomerInformationView from './Views/AskCustomerInformationView';
import ChooseFerriteView from './Views/ChooseFerriteView';
import ChooseHousingView from './Views/ChooseHousingView';
import ChooseStickerView from './Views/ChooseStickerView';
import CreateHousingView from './Views/CreateHousingView';
import ChooseMemberView from './Views/MemberSelectView';
import PaymentView from './Views/PaymentView';

export interface ViewsInterface {
  step?: number;
  hidden?: boolean;
  component: any;
  shortTitle: string;
  title: string;
  describe: string;
  inputs: any;
  slug: string;
  buttonEventClass: string;
  wrapperSize?: 'sm' | 'md' | 'lg';
  isValidate?: (obj: any) => boolean;
  getOldPrice?: (obj: any) => number;
  getPrice?: (obj: any) => number;
}

export const VIEWS: ViewsInterface[] = [
  // {
  //   step: 1,
  //   component: AskCustomerInformationView,
  //   shortTitle: 'Create Housing',
  //   slug: 'customerInfos',
  //   buttonEventClass: 'customerInfosIsSet',
  //   title: 'Information [utilisateur] ?',
  //   describe: 'Merci de nous indiquer le type de logement que vous occupez, afin de vous proposer l’offre la plus adaptée à votre domicile.',
  //   inputs: {
  //     firstName: {
  //       type: 'string',
  //       defaultValue: null
  //     },
  //     lastName: {
  //       type: 'string',
  //       defaultValue: null
  //     },
  //     email: {
  //       type: 'string',
  //       defaultValue: null
  //     }
  //   },
  //   wrapperSize: 'lg',
  //   isValidate: (obj: any) => {
  //     return true;
  //     // const { nom, complement, etage, porte, address, coverImage, logo } = obj.Housing;
  //     // return !!nom && !!complement && !!etage && !!porte && !!address && !!coverImage && !!logo;
  //   }
  // },
  {
    component: ChooseHousingView,
    shortTitle: "Type de logement",
    slug: 'housingChoice',
    buttonEventClass: 'housingChoiceIsSet',
    title: "Quel est votre [type de logement] ?",
    describe: "Merci de nous indiquer le type de logement que vous occupez, afin de vous proposer l'offre la plus adaptée à votre domicile.",
    inputs: {
      equipmentType: {
        type: "string",
        defaultValue: null,
        shouldBe: ['individual', 'condo']
      },
      isBusiness: {
        type: "boolean",
        defaultValue: false,
      }
    },
    wrapperSize: 'lg',
    isValidate: (obj: any) => {
      return !!obj.equipmentType;
    },
    getOldPrice: (obj: any) => {
      return obj.isBusiness 
        ? 79.90 
        : (obj.equipmentType === 'individual' && !obj.stickerType)
          ? 59.90
          : 49.90;
    },
    getPrice: (obj: any) => {
      return obj.isBusiness 
        ? 39.90 
        : (obj.equipmentType === 'individual' && !obj.stickerType)
          ? 29.90 
          : 19.90;
    }
  },
  {
    component: ChooseStickerView,
    shortTitle: "Type de plaque NFC",
    slug: 'stickerChoice',
    buttonEventClass: 'stickerChoiceIsSet',
    title: "Quel [type de plaque NFC] souhaitez-vous installer ?",
    describe: "Le type de plaque NFC dépend de la surface sur laquelle vous souhaitez l’installer.",
    inputs: {
      stickerType: {
        type: "string",
        defaultValue: null,
        shouldBe: ['glue', 'screw', 'nude', 'none']
      },
    },
    wrapperSize: 'lg',
    isValidate: (obj: any) => {
      return !!obj.stickerType;
    },
    getOldPrice: (obj: any) => {
      return obj.stickerType && obj.stickerType !== 'none' ? 10 : 0;
    },
    getPrice: (obj: any) => {
      return obj.stickerType && obj.stickerType !== 'none' ? 10 : 0;
    }
  },
  {
    component: ChooseFerriteView,
    shortTitle: 'Anti-métal',
    slug: 'ferriteChoice',
    buttonEventClass: 'ferriteChoiceIsSet',
    title: 'Déposez-vous votre plaque NFC sur une [surface métallique] ?',
    describe: 'Pour rendre une plaque NFC fonctionnelle sur une surface métallique,\nil faut impérativement lui ajouter de l’anti-métal.',
    inputs: {
      withFerrite: {
        type: 'boolean',
        defaultValue: null
      },
    },
    wrapperSize: 'md',
    isValidate: (obj: any) => {
      return typeof obj.withFerrite === 'boolean';
    },
    getOldPrice: (obj: any) => {
      return !obj.withFerrite ? 0 : 19.90;
    },
    getPrice: (obj: any) => {
      return !obj.withFerrite ? 0 : 19.90;
    },
  },
  {
    component: ChooseMemberView,
    shortTitle: '+ de membres',
    slug: 'memberChoice',
    buttonEventClass: 'memberChoiceIsSet',
    title: 'Ajouter [des membres supplémentaires]',
    describe: `Vous ne souhaitez pas être le seul à recevoir les appels lorsqu’un visiteur se présente ?\nFaites profiter des avantages CleanCall aux autres membres de votre {TYPE_LOGEMENT}.`,
    inputs: {
      subscriptionMode: {
        type: 'string',
        defaultValue: 'lifeTime',
        shouldBe: ['monthly', 'annually', 'lifeTime']
      },
      additionalMembersCount: {
        type: 'number',
        defaultValue: null,
        shouldBe: [0, 1, 5, 20]
      },
    },
    wrapperSize: 'lg',
    isValidate: (obj: any) => {
      return typeof obj.additionalMembersCount === 'number' && obj.subscriptionMode;
    },
    getOldPrice: (obj: any) => {
      const count = obj.additionalMembersCount;
      if(count === 0) return 0;
      if(obj.subscriptionMode === 'monthly'){
        if(obj.isBusiness){
          return count === 1 ? 2.9
            : count === 5 ? 14.5
              : count === 20 ? 58 : 0;
        }
        return count === 1 ? 1.9
          : count === 5 ? 9.5
            : count === 20 ? 38 : 0;
      }else if(obj.subscriptionMode === 'annually'){
        return count === 1 ? 7.9
          : count === 5 ? 39.5
            : count === 20 ? 158 : 0;
      }else if(obj.subscriptionMode === 'lifeTime'){
        return count === 1 ? 24.9
          : count === 5 ? 124.5
            : count === 20 ? 498 : 0;
      }
      return 0;
    },
    getPrice: (obj: any) => {
      const count = obj.additionalMembersCount;
      if(count === 0) return 0;
      if(obj.subscriptionMode === 'monthly'){
        if(obj.isBusiness){
          return count === 1 ? 2.9
            : count === 5 ? 11.6
              : count === 20 ? 43.5 : 0;
        }
        return count === 1 ? 1.9
          : count === 5 ? 7.6
            : count === 20 ? 28.5 : 0;
      }else if(obj.subscriptionMode === 'annually'){
        return count === 1 ? 7.9
          : count === 5 ? 31.6
            : count === 20 ? 118.5 : 0;
      }else if(obj.subscriptionMode === 'lifeTime'){
        return count === 1 ? 24.9
          : count === 5 ? 99.6
            : count === 20 ? 373.50 : 0;
      }
      return 0;
    }
  },
  // {
  //   component: CreateHousingView,
  //   shortTitle: 'Create Housing',
  //   slug: 'housingInfos',
  //   buttonEventClass: 'housingInfosIsSet',
  //   title: 'Quel est votre [type de logement] ?',
  //   describe: 'Merci de nous indiquer le type de logement que vous occupez, afin de vous proposer l’offre la plus adaptée à votre domicile.',
  //   inputs: {
  //     coverImage: {
  //       type: 'string',
  //       defaultValue: null
  //     },
  //     logo: {
  //       type: 'string',
  //       defaultValue: null
  //     },
  //     nom: {
  //       type: 'string',
  //       defaultValue: null
  //     },
  //     address: {
  //       type: 'string',
  //       defaultValue: null
  //     },
  //     number: {
  //       type: 'string',
  //       defaultValue: null
  //     },
  //     complement: {
  //       type: 'string',
  //       defaultValue: null
  //     },
  //     etage: {
  //       type: 'string',
  //       defaultValue: null
  //     },
  //     porte: {
  //       type: 'string',
  //       defaultValue: null
  //     }
  //   },
  //   wrapperSize: 'lg',
  //   isValidate: (obj: any) => {
  //     return true;
  //     // const { nom, complement, etage, porte, address, coverImage, logo } = obj.Housing;
  //     // return !!nom && !!complement && !!etage && !!porte && !!address && !!coverImage && !!logo;
  //   }
  // },
  {
    component: PaymentView,
    shortTitle: 'Récapitulatif',
    slug: 'resume',
    buttonEventClass: 'payWithStripe',
    title: 'Récapitulatif de la [commande]',
    describe: 'Si vous avez la moindre question, n’hésitez pas à nous contacter par email : [hello@cleancall.fr]',
    wrapperSize: 'lg',
    inputs: {},
    isValidate: () => true
  }
];
