import { AxiosError } from 'axios';

import DataApi from './DataApi';

class Http {
  private errorHandler = (err: AxiosError) => {
    if (err.response) {
      return Promise.reject(err.response.data);
    } else if (err.request) {
      console.log('==============REQUEST======================');
      console.log('🚩', err.request);
      console.log('==============REQUEST======================');
      return Promise.reject({ status: 404, message: err.request._response });
    } else {
      console.log('==============OTHER ERROR======================');
      console.log('⛔', err);
      console.log('==============OTHER ERROR======================');
      return Promise.reject({ status: 500, message: err.message });
    }
  };

  formHeaders = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  get = async (url: string, config?: any) => {
    return DataApi.instance.get(url, config)
      .then((res: any) => res.data)
      .catch((err: AxiosError) => this.errorHandler(err));

  };

  post = async (url: string, data?: any, config?: any) => {
    return DataApi.instance.post(url, data, config)
      .then((res: any) => res.data)
      .catch((err: AxiosError) => this.errorHandler(err));

  };

  put = async (url: string, data?: any, config?: any) => {
    return DataApi.instance.put(url, data, config)
      .then((res: any) => res.data)
      .catch((err: AxiosError) => this.errorHandler(err));
  };

  delete = async (url: string, config?: any) => {
    return DataApi.instance.delete(url, config)
      .then((res: any) => res.data)
      .catch((err: AxiosError) => this.errorHandler(err));
  };
}

export default new Http();
