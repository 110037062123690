import './PaymentView.scss';

import clsx from 'clsx';
import { useMemo } from 'react';

import GenerateStringColor from '../../../../components/GenerateStringColor';
import { COLOR } from '../../../../constants/Constant';
import { usePaymentProcess } from '../../../../hooks/PaymentProcessService';
import { business, condoHousing, individualHousing } from '../../../../services/Images';
import Utils from '../../../../utils/Utils';

interface ProductLineInterface {
  name: string;
  price?: string;
  color?: string;
  extraClass?: string;
}

const ProductLine = ({
  name,
  price,
  color = COLOR.GREY,
  extraClass
}: ProductLineInterface) => {
  const className = clsx('line', extraClass);

  return <div className={className}>
    <GenerateStringColor className='name' text={name} codeColor={color} />
    {price && <p className='price'>{price}</p>}
  </div>;
};

const PaymentView = () => {
  const { form, originalPrice, reducedPrice, getDutyFreeWord, parsedStickersType, getPrimaryProductOldPrice, getAntiMetalPrice, getAdditionalMembersOldPrice } = usePaymentProcess();

  const housingInfos = useMemo(() => {
    const { isBusiness, equipmentType } = form;
    if (isBusiness) {
      if (equipmentType === 'condo') {
        return {
          pre: 'Entreprise',
          text: 'Copropriété',
          imageColor: COLOR.PURPLE,
          color: COLOR.ORANGE,
          image: business
        };
      }
      return {
        pre: 'Entreprise',
        text: 'Maison Individuelle',
        imageColor: COLOR.PURPLE,
        color: COLOR.GREEN,
        image: business
      };
    }
    else if (equipmentType === 'individual') return {
      pre: 'Logement',
      text: 'Maison Individuelle',
      color: COLOR.GREEN,
      image: individualHousing
    };
    else if (equipmentType === 'condo') return {
      pre: 'Logement',
      text: 'Copropriété',
      color: COLOR.ORANGE,
      image: condoHousing
    };
    return {
      pre: 'Logement',
      text: 'Maison Individuelle',
      color: COLOR.GREEN,
      image: individualHousing
    };
  }, [form]);

  const additionalMemberSentence = useMemo(() => {
    return Utils.accorization(form.additionalMembersCount, 'membre supplémentaire', true, 'aucun membre supplémentaire');
  }, [form.additionalMembersCount]);

  const additionalPurchase = useMemo(() => {
    const { withFerrite, additionalMembersCount } = form;
    return !withFerrite && !additionalMembersCount ? undefined : { withFerrite, additionalMembersCount };
  }, [form.withFerrite, form.additionalMembersCount]);

  const parsedStickerTypeSentence = useMemo(() => {
    return parsedStickersType.find(pst => pst.value === form.stickerType)?.sentence;
  }, []);

  const _originalPrice = (`${originalPrice.toFixed(2)}€ ${getDutyFreeWord}`).trim();
  const _reducePrice = (`${reducedPrice.toFixed(2)}€ ${getDutyFreeWord}`).trim();

  return (
    <div className='resume-bloc'>
      <div className="top-part">
        <div className='image-container'>
          <div className="color-overlay" style={{ backgroundColor: housingInfos.imageColor || housingInfos.color }} />
          <img src={housingInfos.image} />
        </div>

        <div className="products-lines">
          <ProductLine
            name={`${housingInfos.pre} en [${housingInfos.text}]`}
            price={`${getPrimaryProductOldPrice.toFixed(2)}€`}
            color={housingInfos.color}
            extraClass='title'
          />
          <ProductLine name={`Comprenant [1 membre] ${parsedStickerTypeSentence}`} />
          {
            additionalPurchase && (
              <>
                <div className="separator" />
                <ProductLine name='Achat additionnel' extraClass='sub-title' />
                {
                  additionalPurchase.withFerrite && <ProductLine name='Option anti-métal' price={`${getAntiMetalPrice.toFixed(2)}€`} />
                }
                {
                  additionalPurchase.additionalMembersCount && <ProductLine name={additionalMemberSentence} price={`${getAdditionalMembersOldPrice.toFixed(2)}€`} />
                }
              </>
            )
          }
        </div>
      </div>

      <div className="billing-lines">
        <p className='title'>Total</p>
        <div className="billing-info">
          {originalPrice > reducedPrice && <p className='original-price'>{_originalPrice}</p>}
          <p className='discounted-price'>{_reducePrice}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentView;
