import { createContext, useContext, useMemo, useState } from 'react';

import { EquipmentType } from '../interfaces/model/EquipmentInterface';
import { HousingInterface } from '../interfaces/model/HousingInterface';
import { StickerMaterialType, StickerTypeType } from '../interfaces/model/StickerInterface';
import { VIEWS } from '../screens/PaymentProcessScreen/steps';
import { stickersType } from '../screens/PaymentProcessScreen/Views/ChooseStickerView';
import { SubscriptionModeType, subscriptionModeValues } from '../screens/PaymentProcessScreen/Views/MemberSelectView';
import { VIEWS_V1 } from '../screens/PaymentProcessScreenV1/steps';

export type PriceType = {
  reducedPrice: number;
  originalPrice: number;
};

type PaymentProcessContextType = {
  form: any;
  step: number;
  housing?: HousingInterface;
  moreMembers?: boolean;
  parsedViews: any[];
  parsedStickersType: any[];
  getDutyFreeWord: 'HT' | 'TTC' | '';
  getPrimaryProductOldPrice: number;
  getPrimaryProductPrice: number;
  getAntiMetalPrice: number;
  getAdditionalMembersOldPrice: number;
  getAdditionalMembersPrice: number;
  reducedPrice: number;
  originalPrice: number;
  // getPriceList: () => PriceType;
  setForm: (obj: any) => void;
  setStep: (step: number) => void;
  setHousing: (housing: HousingInterface) => void;
  setMoreMembers: (moreMembers?: boolean) => void;
};

const defaultHousingValue: any = {
  step: 1,
  form: {
    customerEmail: null,
    isBusiness: false,
    equipmentType: null,
    stickerType: null,
    stickerMaterial: null,
    withFerrite: false,
    quantity: 1,
    additionalMembersCount: null,
    subscriptionMode: null,
    isClearance: false,
    housing: {
      nom: '',
      logo: '',
      porte: '',
      etage: '',
      number: '',
      address: '',
      complement: '',
      coverImage: '',
    }
  },
  setForm: (obj: any) => obj,
  setStep: (step: number) => step
};

export interface PaiementPayload {
  customerEmail?: string;
  housingId?: string;
  equipmentType?: EquipmentType;
  isBusiness: boolean;
  stickerType?: StickerTypeType | 'none';
  stickerMaterial?: StickerMaterialType;
  withFerrite?: boolean;
  quantity?: number;
  additionalMembersCount?: number;
  subscriptionMode?: SubscriptionModeType;
  isClearance?: boolean;
}

export const PaymentProcessContext = createContext<PaymentProcessContextType>(defaultHousingValue);

export const usePaymentProcess = () => {
  return useContext(PaymentProcessContext);
};

export const getEquipmentTypeSentence = (equipmentType?: EquipmentType, isBusiness?: boolean) => {
  if(isBusiness) return 'entreprise';
  else if(equipmentType === 'individual') return 'maison individuelle';
  else if(equipmentType === 'condo') return 'logement en copropriété';
  else return 'logement';
};

export const getPriceExtension = (subscriptionMode: SubscriptionModeType) => {
  return (subscriptionModeValues.find(smv => smv.value === subscriptionMode))?.text || subscriptionModeValues[0].text;
};

const PaymentProcessService = ({ children }: any) => {
  const [moreMembers, setMoreMembers] = useState<boolean>();
  const [housing, setHousing] = useState<HousingInterface>();
  const [step, setStep] = useState<number>(1);
  const [form, setForm] = useState<PaiementPayload>({
    customerEmail: undefined,
    housingId: undefined,
    equipmentType: undefined,
    isBusiness: false,
    stickerType: undefined,
    stickerMaterial: undefined,
    withFerrite: undefined,
    additionalMembersCount: undefined,
    quantity: 1,
    subscriptionMode: 'lifeTime',
    isClearance: false
  });

  const parsedViews = useMemo(() => {
    if(window.location.pathname.includes('buy-v1')){
      return VIEWS_V1.map((v: any, index: number) => ({...v, step: index + 1}));
    }
    
    return VIEWS.map((view: any) => {
      const typeLogement = getEquipmentTypeSentence(form.equipmentType, form.isBusiness);
      return {
        ...view,
        describe: view.describe.replace('{TYPE_LOGEMENT}', typeLogement)
      };
    })
      .filter(v => form.stickerType === 'none' ? v.slug !== 'ferriteChoice' : true)
      .filter(v => moreMembers ? v.slug === 'memberChoice' : v.slug !== 'memberChoice')
      .filter(v => housing ? v.slug !== 'housingChoice' : true)
      .map((v: any, index: number) => ({...v, step: index + 1}) );
  }, [form.equipmentType, form.isBusiness, form.stickerType, moreMembers]);
  
  const calculationParsedViews = useMemo(() => {
    if(window.location.pathname.includes('buy-v1')){
      return VIEWS_V1.map((v: any, index: number) => ({...v, step: index + 1}) );
    }

    return VIEWS
      .filter(v => form.stickerType === 'none' ? v.slug !== 'ferriteChoice' : true)
      .filter(v => moreMembers ? v.slug === 'memberChoice' : v.slug !== 'memberChoice')
      .map((v: any, index: number) => ({...v, step: index + 1}) );
  }, [form.equipmentType, form.isBusiness, form.stickerType, moreMembers]);

  const parsedStickersType = useMemo(() => {
    return stickersType.map((st: any) => {
      return {
        ...st,
        // WIP : METAL IS NOT AVAILABLE NOW
        // sentence: st.sentence.replace('{MATERIAL}', form.isBusiness ? 'métal' : 'PVC'),
        sentence: st.sentence.replace('{MATERIAL}', form.isBusiness ? 'PVC' : 'PVC'),
        describe: st.describe.replace('{BUSINESS_TYPE}', form.isBusiness ? 'entreprise' : 'logement')
      };
    }).filter(v => form.equipmentType === 'individual' || window.location.pathname.includes('buy-v1') ? v.value !== 'none' : true);
  }, [form.isBusiness, form.equipmentType]);

  const getDutyFreeWord = useMemo(() => form.isBusiness ? 'HT' : '', [form.isBusiness]);

  const getPrimaryProductOldPrice = useMemo(() => {
    return calculationParsedViews.reduce((accumulator: any, currentValue: any) => {
      const getThisPrice = Object.keys(currentValue.inputs).find(key => key === 'isBusiness' || key === 'stickerType');
      return getThisPrice ? accumulator + currentValue.getOldPrice(form) : accumulator;
    }, 0);
  }, [form]);

  const getPrimaryProductPrice = useMemo(() => {
    return calculationParsedViews.reduce((accumulator: any, currentValue: any) => {
      const getThisPrice = Object.keys(currentValue.inputs).find(key => key === 'isBusiness' || key === 'stickerType');
      return getThisPrice ? accumulator + currentValue.getPrice(form) : accumulator;
    }, 0);
  }, [form]);
  
  const getAntiMetalPrice = useMemo(() => {
    return parsedViews.reduce((accumulator: any, currentValue: any) => {
      const getThisPrice = Object.keys(currentValue.inputs).find(key => key === 'withFerrite');
      return getThisPrice ? accumulator + currentValue.getPrice(form) : accumulator;
    }, 0);
  }, [form.withFerrite]);
  
  const getAdditionalMembersOldPrice = useMemo(() => {
    return calculationParsedViews.reduce((accumulator: any, currentValue: any) => {
      const getThisPrice = Object.keys(currentValue.inputs).find(key => key === 'additionalMembersCount');
      return getThisPrice ? accumulator + currentValue.getOldPrice(form) : accumulator;
    }, 0);
  }, [form.additionalMembersCount]);
  
  const getAdditionalMembersPrice = useMemo(() => {
    return calculationParsedViews.reduce((accumulator: any, currentValue: any) => {
      const getThisPrice = Object.keys(currentValue.inputs).find(key => key === 'additionalMembersCount');
      return getThisPrice ? accumulator + currentValue.getPrice(form) : accumulator;
    }, 0);
  }, [form.additionalMembersCount]);

  const reducedPrice = useMemo(() => {
    return calculationParsedViews.reduce((accumulator: any, currentValue: any) => {
      const havePrice = !!currentValue.getPrice;
      return havePrice ? accumulator + currentValue.getPrice(form) : accumulator;
    }, 0);
  }, [form]);
  
  const originalPrice = useMemo(() => {
    return calculationParsedViews.reduce((accumulator: any, currentValue: any) => {
      const havePrice = !!currentValue.getPrice;
      return havePrice ? accumulator + currentValue.getOldPrice(form) : accumulator;
    }, 0);
  }, [form]);

  return (
    <PaymentProcessContext.Provider value={{
      form,
      step,
      housing,
      moreMembers,
      setForm,
      setStep,
      setHousing,
      setMoreMembers,
      parsedViews,
      parsedStickersType,
      getDutyFreeWord,
      getPrimaryProductOldPrice,
      getPrimaryProductPrice,
      getAntiMetalPrice,
      getAdditionalMembersOldPrice,
      getAdditionalMembersPrice,
      reducedPrice,
      originalPrice
    }}>
      {children}
    </PaymentProcessContext.Provider>
  );
};

export default PaymentProcessService;
