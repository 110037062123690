import './ChooseHousingView.scss';

import OneLineSwitch from '../../../../components/OneLineSwitch';
import SimpleCard, { SimpleCardInterface } from '../../../../components/SimpleCard';
import { COLOR } from '../../../../constants/Constant';
import { usePaymentProcess } from '../../../../hooks/PaymentProcessService';
import { business ,condoHousing, individualHousing } from '../../../../services/Images';

const cards: SimpleCardInterface[] = [
  {
    value: 'individual',
    title: 'Maison Individuelle',
    describe: 'La plaque NFC CleanCall vous appartient intégralement et se partage avec les membres de votre foyer !',
    color: COLOR.GREEN,
    image: individualHousing,
    imgHeight: 90
  },
  {
    value: 'condo',
    title: 'Copropriété',
    describe: 'La plaque NFC CleanCall est partagée par les différents logements. Les livreurs et visiteurs choisissent le foyer avant de sonner !',
    color: COLOR.ORANGE,
    image: condoHousing,
    imgHeight: 120
  }
];

const ChooseHousingView = () => {

  const { form, setForm } = usePaymentProcess();

  const _toogleIsBusiness = () => {
    setForm((f: any) => ({...f, isBusiness: !f.isBusiness}));
  };

  const _onSelect = (type: string) => {
    setForm({
      ...form,
      equipmentType: type,
      stickerType: undefined,
      withFerrite: undefined
    });
  };

  return (
    <>
      <div className='simple-card-row'>
        {
          cards.map(({
            value,
            imgHeight,
            title,
            describe,
            color,
            image
          }: SimpleCardInterface, index: number) => (
            <SimpleCard
              key={index}
              value={value}
              title={title}
              describe={describe}
              color={color}
              image={image}
              imgHeight={imgHeight}
              onSelect={(val: string) => _onSelect(val)}
              selected={form.equipmentType === value}
            />
          ))
        }
      </div>
      <OneLineSwitch
        label='Je suis une entreprise'
        describe='Si vous installez CleanCall pour les bureaux de votre entreprise, vous bénéficierez de fonctionnalités exclusives aux entreprises (logo, formulaire de trie, et plus encore).'
        image={business}
        checked={form.isBusiness}
        onChange={_toogleIsBusiness}
      />
    </>
  );
};

export default ChooseHousingView;
