import './PaymentView.scss';

import clsx from 'clsx';
import { useMemo } from 'react';

import GenerateStringColor from '../../../../components/GenerateStringColor';
import { COLOR } from '../../../../constants/Constant';
import { usePaymentProcess } from '../../../../hooks/PaymentProcessService';
import Utils from '../../../../utils/Utils';

interface ProductLineInterface {
  name: string;
  price?: string;
  color?: string;
  extraClass?: string;
}

const ProductLine = ({
  name,
  price,
  color = COLOR.GREY,
  extraClass
}: ProductLineInterface) => {
  const className = clsx('line', extraClass);

  return <div className={className}>
    <GenerateStringColor className='name' text={name} codeColor={color} />
    {price && <p className='price'>{price}</p>}
  </div>;
};

const PaymentView = () => {
  const { form, originalPrice, reducedPrice, getDutyFreeWord, parsedStickersType, getPrimaryProductOldPrice, getAntiMetalPrice, getAdditionalMembersOldPrice } = usePaymentProcess();

  const additionalMemberSentence = useMemo(() => {
    return Utils.accorization(form.additionalMembersCount, 'membre supplémentaire', true, 'aucun membre supplémentaire');
  }, [form.additionalMembersCount]);

  const additionalPurchase = useMemo(() => {
    const { withFerrite, additionalMembersCount } = form;
    return !withFerrite && !additionalMembersCount ? undefined : { withFerrite, additionalMembersCount };
  }, [form.withFerrite, form.additionalMembersCount]);
  
  const parsedStickerTypeObj = useMemo(() => {
    return parsedStickersType.find(pst => pst.value === form.stickerType);
  }, []);

  const stickerInfos = useMemo(() => {
    return {
      text: parsedStickerTypeObj.title,
      imageColor: COLOR.BLUE,
      color: COLOR.BLUE,
      image: parsedStickerTypeObj.image
    };
  }, [form]);

  const _originalPrice = (`${originalPrice.toFixed(2)}€ ${getDutyFreeWord}`).trim();
  const _reducePrice = (`${reducedPrice.toFixed(2)}€ ${getDutyFreeWord}`).trim();

  return (
    <div className='resume-bloc'>
      <div className="top-part">
        <div className='image-container'>
          <div className="color-overlay" style={{ backgroundColor: stickerInfos.imageColor || stickerInfos.color }} />
          <img src={stickerInfos.image} />
        </div>

        <div className="products-lines">
          <ProductLine
            name={stickerInfos.text}
            price={`${getPrimaryProductOldPrice.toFixed(2)}€`}
            color={stickerInfos.color}
            extraClass='title'
          />
          <ProductLine 
            name={parsedStickerTypeObj.describe}
            extraClass='break-line'
          />
          {
            additionalPurchase && (
              <>
                <div className="separator" />
                <ProductLine name='Achat additionnel' extraClass='sub-title' />
                {
                  additionalPurchase.withFerrite && <ProductLine name='Option anti-métal' price={`${getAntiMetalPrice.toFixed(2)}€`} />
                }
                {
                  additionalPurchase.additionalMembersCount && <ProductLine name={additionalMemberSentence} price={`${getAdditionalMembersOldPrice.toFixed(2)}€`} />
                }
              </>
            )
          }
        </div>
      </div>

      <div className="billing-lines">
        <p className='title'>Total</p>
        <div className="billing-info">
          {originalPrice > reducedPrice && <p className='original-price'>{_originalPrice}</p>}
          <p className='discounted-price'>{_reducePrice}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentView;
