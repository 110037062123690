import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { hotjar } from 'react-hotjar';
import { BrowserRouter as Router } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import AuthService from './hooks/AuthService';
import CallService from './hooks/CallService';
import { useEffectOnce } from './hooks/UseEffectOnce';
// import { useEffectOnce } from './hooks/UseEffectOnce';
import RouteLists from './Routes';
import Utils from './utils/Utils';
// import { preloadStaticImages } from './services/Images';

const App = (): JSX.Element => {

  // useEffectOnce(() => {
  //   preloadStaticImages();
  // });

  useEffectOnce(() => {
    Utils.setVHProperty();
    window.addEventListener('resize', Utils.setVHProperty);
    return () => {
      window.removeEventListener('resize', Utils.setVHProperty);
    };
  });

  useEffectOnce(() => {
    if(window.location.pathname === '/buy'){
      window.location.replace('/buy-v1');
    }
  });
  
  useEffectOnce(() => {
    hotjar.initialize(3315210, 6);
  });
  
  useEffectOnce(() => {
    Utils.setDefaultBackgroundColor();
  });

  const beforeInstallPrompt = (e: any) => {
    e.preventDefault();
  };

  useEffectOnce(() => {
    window.addEventListener('beforeinstallprompt', beforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPrompt);
    };
  });

  return (
    <CallService>
      <AuthService>
        <Router>
          <RouteLists />
        </Router>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          limit={1}
          transition={Slide}
          hideProgressBar
        />
      </AuthService>
    </CallService>
  );
};

export default App;
