import './CCLottie.scss';

import clsx from "clsx";
import lottie from 'lottie-web/build/player/lottie_light.min.js';
import { useMemo, useRef } from "react";

import check from '../../assets/lottie/circle-check-blue.json';
import checkGreen from '../../assets/lottie/circle-check-green.json';
import circleError from '../../assets/lottie/circle-error.json';
import circle from '../../assets/lottie/loader.json';
import nfcBlack from '../../assets/lottie/nfc_loader_black.json';
import nfcGrey from '../../assets/lottie/nfc_loader_grey.json';
import nfcWhite from '../../assets/lottie/nfc_loader_white.json';
import { useEffectOnce } from '../../hooks/UseEffectOnce';

export type lottieNameTypes = 'nfc-white' | 'nfc-black' | 'nfc-grey' | 'circle' | 'circle-error' | 'check' | 'check-green';
interface CCLottieInterface {
  loop?: boolean;
  autoplay?: boolean;
  name?: lottieNameTypes;
}

const CCLottie = ({
  loop = true,
  autoplay = true,
  name = 'nfc-black'
}: CCLottieInterface) => {
  const anime = useRef(null);

  const _animationData = useMemo(() => {
    if(name === 'check') return check;
    else if(name === 'check-green') return checkGreen;
    else if(name === 'circle-error') return circleError;
    else if(name === 'nfc-white') return nfcWhite;
    else if(name === 'nfc-black') return nfcBlack;
    else if(name === 'nfc-grey') return nfcGrey;
    else return circle;
  }, [name]);

  const className = clsx(
    'lottie-container',
    name
  );

  useEffectOnce(() => {
    lottie.loadAnimation({
      container: anime.current,
      animationData: _animationData,
      renderer: "svg",
      loop,
      autoplay
    });
  
    return () => lottie.stop();
  });
  

  return (
    <div ref={anime} className={className} />
  );
};

export default CCLottie;
