import { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { logout, logUserFromStorage } from 'src/services/AuthService';

import { useEffectOnce } from './UseEffectOnce';

type AuthContextType = {
  userInfo: any;
  isLoading: boolean;
  setUserInfo: (userInfo: any) => void;
  setIsLoading: (isLoading: boolean) => void;
}

const defaultAuthValue: any = {
  userInfo: {},
  isLoading: false,
  setUserInfo: (userInfo: any) => userInfo,
  setIsLoading: (isLoading: boolean) => isLoading
};

export const AuthContext = createContext<AuthContextType>(defaultAuthValue);

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthService = ({ children }: any) => {
  const [userInfo, setUserInfo] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffectOnce(() => {
    _checkSession();
  });

  const _checkSession = async () => {
    try {
      setIsLoading(true);
      const user = await logUserFromStorage();
      setUserInfo(user);
    } catch (err: any) {
      toast.error(err.message);
      Object.keys(userInfo).length && logout(userInfo);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{
      userInfo,
      isLoading,
      setUserInfo,
      setIsLoading
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthService;
