import './WarningBox.scss';

import { memo } from 'react';

import { WarningLogo } from '../../services/Images';

export interface WarningBoxInterface {
  message: string;
}

const WarningBox = ({
  message
}: WarningBoxInterface) => {

  return (
    <div className='warning-container'>
      <img src={WarningLogo} className='logo' />
      <p className='message'>
        {message}
      </p>
    </div>
  );
};

export default memo(WarningBox);
