import './GenerateStringColor.scss';

import clsx from 'clsx';
import { CSSProperties } from 'react';

import { COLOR } from '../../constants/Constant';

type TypeInterface = 'text' | 'title' | 'bigTitle';
interface GenerateStringColorInterface {
  text: string;
  type?: TypeInterface;
  right?: boolean;
  codeColor?: string;
  className?: string;
  highlightClassName?: string;
  style?: CSSProperties;
  disableHighlightButton?: boolean;
  onHighlightPress?: () => void;
}

const GenerateStringColor = ({
  text,
  type = 'text',
  right,
  className = '',
  highlightClassName = '',
  style,
  codeColor = COLOR.BLUE,
  onHighlightPress
}: GenerateStringColorInterface) => {
  const chars = [...Array.from(text)];
  const textParts = [] as any[];
  let buffer = '';

  const _highlightClassName = clsx(
    'highlight',
    highlightClassName
  );

  chars.forEach((c, index) => {
    if (c === '[') {
      if (index !== 0) {
        textParts.push(buffer);
        buffer = '';
      } else {
        right && textParts.push(buffer + ' ');
      }
    } else if (c === ']') {
      textParts.push(
        onHighlightPress
          ? (
            <a 
              key={index.toString()}
              onClick={onHighlightPress}
              className={_highlightClassName}
            >
              <span style={{color: codeColor}}>
                {buffer}
              </span> 
            </a> 
          )
          : (
            <span
              key={index.toString()}
              className={_highlightClassName}
              style={{color: codeColor}}
            >
              {buffer}
            </span>
          )
      );
      buffer = '';
    } else {
      buffer += c;
    }
  });
  if (buffer?.length > 0) textParts.push(buffer);

  const _className = clsx(
    'generate-string-color',
    type === 'title' && 'title',
    type === 'bigTitle' && 'big-title',
    className
  );

  return (
    <p className={_className} style={style}>
      {textParts}
    </p>
  );
};

export default GenerateStringColor;
