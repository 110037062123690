class Interval {
  intervals = new Set();
  
  make = (fn: () => void, interval: number) => {
    const newInterval = setInterval(() => fn.apply(null), interval);
    this.intervals.add(newInterval);
    return newInterval;
  };

  clear = (id: any) => {
    this.intervals.delete(id);
    return clearInterval(id);
  };

  clearAll = () => {
    for (const id of this.intervals) {
      this.clear(id);
    }
  };
}

export default new Interval();
