import './Header.scss';

import { memo } from 'react';

import { logoPartialBlack } from '../../services/Images';

const Logo = () => (
  <div className='logo-container'>
    <img src={logoPartialBlack} className='logo' />
  </div>
);

export default memo(Logo);
