import './ChooseStickerView.scss';

import ColumnCard, { ColumnCardInterface } from '../../../../components/ColumnCard';
import { usePaymentProcess } from '../../../../hooks/PaymentProcessService';
import { stickerGlue, stickerNone, stickerNude, stickerScrew } from '../../../../services/Images';

export const stickersType: (ColumnCardInterface | any)[] = [
  {
    value: 'glue',
    title: 'Plaque à coller',
    describe: 'Cette plaque NFC avec colle 3M sera parfaite pour toutes les surfaces lisses et non poreuses.',
    sentence: 'et [1 plaque NFC en {MATERIAL} à coller]',
    image: stickerGlue
  },
  {
    value: 'screw',
    title: 'Plaque à visser',
    describe: "Cette plaque NFC perforée sera le bon choix, si la zone d'installation peut accueillir des vis.\n\n Vis et chevilles incluses.",
    sentence: 'et [1 plaque NFC en {MATERIAL} à visser]',
    image: stickerScrew
  },
  {
    value: 'nude',
    title: 'Plaque nue',
    describe: 'Cette plaque NFC sans trous, ni colle sera le bon choix pour une installation sur tous types de surface, par vos propres moyens (Mastic-colle idéalement).',
    sentence: 'et [1 plaque NFC en {MATERIAL} nue]',
    image: stickerNude
  },
  {
    value: 'none',
    title: 'Sans plaque',
    describe: "Votre résidence est déjà équipée d'une plaque NFC CleanCall ?\n\n Si oui, inutile d'en commander une nouvelle ! Vous pourrez ajouter votre {BUSINESS_TYPE} sur la plaque NFC existante.",
    sentence: 'sans plaque NFC',
    hasSeparator: true,
    image: stickerNone
  }
];

const ChooseStickerView = () => {

  const { form, setForm, parsedStickersType } = usePaymentProcess();

  const _onSelect = (value: string) => setForm((f: any) => ({ ...f, stickerType: value, withFerrite: value === 'none' ? undefined : f.withFerrite }));

  return (
    <div className='column-card-row'>
      {parsedStickersType.map(({
        value, title, describe, image, hasSeparator
      }: ColumnCardInterface, index: number) => <ColumnCard
        key={index}
        value={value}
        title={title}
        image={image}
        describe={describe}
        selected={form.stickerType === value}
        hasSeparator={hasSeparator}
        onSelect={_onSelect}
      />
      )}
    </div>
  );
};

export default ChooseStickerView;
