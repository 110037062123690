
import './OverlayLoader.scss';

import clsx from 'clsx';
import { memo } from "react";

import CCLottie from '../../components/CCLottie';
import Logo from '../Header/Logo';

interface OverlayLoaderProps {
  visible: boolean;
  inContainer?: boolean;
  showLogo?: boolean;
  showSpinner?: boolean;
}

const OverlayLoader = ({
  visible,
  inContainer,
  showLogo,
  showSpinner = true
}: OverlayLoaderProps) => {

  if(!visible) return null;

  const LoaderContent = () => (
    <>
      <div className='container'>
        {showLogo && <Logo />}
        {showSpinner && (
          <div className='around-loader'>
            <CCLottie name='circle' />
          </div>
        )}
      </div>
    </>
  );

  const className = clsx(
    'overlay-loader',
    inContainer ? "in-container" : 'full-container'
  );

  return (
    <div className={className}>
      <LoaderContent />
    </div>
  );
};

export default memo(OverlayLoader);
