import { lazy, Suspense } from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Route, Routes } from 'react-router-dom';

import PaymentProcessService from './hooks/PaymentProcessService';
import CancelPaymentScreen from './screens/PaymentProcessScreen/Screen/CancelPaymentScreen';
import MoreMembersSuccess from './screens/PaymentProcessScreen/Screen/SuccessPaymentScreen/MoreMembersSuccess';
import NewHousingSuccess from './screens/PaymentProcessScreen/Screen/SuccessPaymentScreen/NewHousingSuccess';
import ClearanceSuccess from './screens/PaymentProcessScreenV1/Screen/SuccessPaymentScreen/ClearanceSuccess';

// import BeginCallScreen from './screens/BeginCallScreen';
// import EndCallScreen from './screens/EndCallScreen';
// import HomeScreen from './screens/HomeScreen';
// import MainScreen from './screens/MainScreen';
// import TestScreen from './screens/TestScreen';
// import VideoCallScreen from './screens/VideoCallScreen';

// const HomeScreen = lazy(() => import(/* webpackChunkName: "HomeScreen" */ './screens/HomeScreen'));
const TestScreen = lazy(() => import(/* webpackChunkName: "TestScreen" */ './screens/TestScreen'));
const MainScreen = lazy(() => import(/* webpackChunkName: "MainScreen" */ './screens/MainScreen'));
const BeginCallScreen = lazy(() => import(/* webpackChunkName: "BeginCallScreen" */ './screens/BeginCallScreen'));
const VideoCallScreen = lazy(() => import(/* webpackChunkName: "VideoCallScreen" */ './screens/VideoCallScreen'));
const EndCallScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/EndCallScreen'));
const LoginScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/LoginScreen'));
const ResetPasswordScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/ResetPasswordScreen'));
const PaymentProcessScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/PaymentProcessScreen'));
const PaymentProcessScreenV1 = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/PaymentProcessScreenV1'));
const BusinessCardScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/BusinessCardScreen'));
const RedirectToStoreScreen = lazy(() => import(/* webpackChunkName: "EndCallScreen" */ './screens/RedirectToStoreScreen'));

const RouteLists = () => {

  return (
    <Routes>
      <Route path="/linkedin" element={
        <LinkedInCallback />
      } />
      <Route path='/' element={
        <Suspense fallback={<>...</>}>
          <LoginScreen />
        </Suspense>
      } />
      <Route path='/test' element={
        <Suspense fallback={<>...</>}>
          <TestScreen />
        </Suspense>
      } />
      <Route path='/sticker' element={
        <Suspense fallback={<>...</>}>
          <MainScreen />
        </Suspense>
      } />
      <Route path='/BeginCallScreen' element={
        <Suspense fallback={<>...</>}>
          <BeginCallScreen />
        </Suspense>
      } />
      <Route path='/VideoCallScreen' element={
        <Suspense fallback={<>...</>}>
          <VideoCallScreen />
        </Suspense>
      } />
      <Route path='/EndCallScreen' element={
        <Suspense fallback={<>...</>}>
          <EndCallScreen />
        </Suspense>
      } />
      <Route path='/reset-password' element={
        <Suspense fallback={<>...</>}>
          <ResetPasswordScreen />
        </Suspense>
      } />
      {/* <Route path='/buy' element={
        <Suspense fallback={<>...</>}>
          <PaymentProcessService>
            <PaymentProcessScreen />
          </PaymentProcessService>
        </Suspense>
      } /> */}
      <Route path='/buy-v1' element={
        <Suspense fallback={<>...</>}>
          <PaymentProcessService>
            <PaymentProcessScreenV1 />
          </PaymentProcessService>
        </Suspense>
      } />
      <Route path='/bs' element={
        <Suspense fallback={<>...</>}>
          <BusinessCardScreen />
        </Suspense>
      } />
      <Route path='/buy/success/clearance' element={
        <Suspense fallback={<>...</>}>
          <ClearanceSuccess />
        </Suspense>
      } />
      <Route path='/buy/success/new-housing' element={
        <Suspense fallback={<>...</>}>
          <NewHousingSuccess />
        </Suspense>
      } />
      <Route path='/buy/success/more-members' element={
        <Suspense fallback={<>...</>}>
          <MoreMembersSuccess />
        </Suspense>
      } />
      <Route path='/buy/cancel' element={
        <Suspense fallback={<>...</>}>
          <CancelPaymentScreen />
        </Suspense>
      } />
      <Route path='/housing/join/*' element={
        <Suspense fallback={<>...</>}>
          <RedirectToStoreScreen />
        </Suspense>
      } />
    </Routes>
  );
};

export default RouteLists;
