
export const cacheImages = async (srcArray: string[], cb?: () => void) => {
  const promises = await srcArray.map((src: string) => {
    return new Promise((resolve: any, reject: any) => {
      const img = new Image();

      img.src = src;
      img.onload = resolve();
      img.onerror = reject();
    });
  });

  await Promise.all(promises);
  console.log('image was loaded');

  return cb;
};

import bgImage from '../assets/image/background.png';
import cleancallLogo from '../assets/image/CleanCall-Logo.png';
import defaultHousing from '../assets/image/default-housing.jpg';
import downloadFromApple from '../assets/image/download-from-appstore.png';
import downloadFromGoogle from '../assets/image/download-from-playstore.png';
import business from '../assets/image/housing/business.png';
import iconEyeclosed from '../assets/image/icon-eye-closed.png';
import iconUserClean from '../assets/image/icon-user-clean.png';
import iconKey from '../assets/image/Key.png';
import logo from '../assets/image/logo.png';
import logoApple from '../assets/image/logo-apple.png';
import logoFacebook from '../assets/image/logo-facebook.png';
import logoGoogle from '../assets/image/logo-google.png';
import logoLinkedIn from '../assets/image/logo-linkedin.png';
import logoPartialWhite from '../assets/image/logo-partial-white.png';
import noPicture from '../assets/image/no-picture.jpg';
import ringButton from '../assets/image/ring-button.png';
import stickerScanIllu from '../assets/image/sticker-scan-illu.png';
import turnDevice from '../assets/image/turn-device.png';
import whiteHousing from '../assets/image/white-housing.png';
import backBtn from './../assets/image/back-btn.png';
import checkIcon from './../assets/image/Check.png';
import iconGroup from './../assets/image/group.png';
import condoHousing from './../assets/image/housing/condo.png';
import doorIcon from './../assets/image/housing/door.png';
import houseIcon from './../assets/image/housing/house.png';
import individualHousing from './../assets/image/housing/individual.png';
import locationIcon from './../assets/image/housing/location.png';
import logoPartialBlack from './../assets/image/housing/logo-partial-black.png';
import pinIcon from './../assets/image/housing/pin.png';
import stageIcon from './../assets/image/housing/stage.png';
import uploadImageIcon from './../assets/image/housing/upload-image-icon.png';
import WarningLogo from './../assets/image/housing/warning.png';
import iconEye from './../assets/image/icon-eye.png';
import logoWithText from './../assets/image/logowithtext.png';
import member1 from './../assets/image/member/1-member.png';
import member5 from './../assets/image/member/5-member.png';
import member20 from './../assets/image/member/20-member.png';
import stickerGlue from './../assets/image/sticker/sticker-glue.png';
import stickerNone from './../assets/image/sticker/sticker-none.png';
import stickerNude from './../assets/image/sticker/sticker-nude.png';
import stickerScrew from './../assets/image/sticker/sticker-screw.png';

export {
  backBtn,
  bgImage,
  business,
  checkIcon,
  cleancallLogo,
  condoHousing,
  defaultHousing,
  doorIcon,
  downloadFromApple,
  downloadFromGoogle,
  houseIcon,
  iconEye,
  iconEyeclosed,
  iconGroup,
  iconKey,
  iconUserClean,
  individualHousing,
  locationIcon,
  logo,
  logoApple,
  logoFacebook,
  logoGoogle,
  logoLinkedIn,
  logoPartialBlack,
  logoPartialWhite,
  logoWithText,
  member1,
  member5,
  member20,
  noPicture,
  pinIcon,
  ringButton,
  stageIcon,
  stickerGlue,
  stickerNone,
  stickerNude,
  stickerScanIllu,
  stickerScrew,
  turnDevice,
  uploadImageIcon,
  WarningLogo,
  whiteHousing
};

// export const preloadStaticImages = async () => {
//   await cacheImages([
//     logo,
//     logoPartialWhite,
//     noPicture,
//     ringButton,
//     stickerScanIllu,
//     iconUserClean,
//     turnDevice
//   ]);
// };

export const preloadPaymentProcessImages = async () => {
  await cacheImages([
    logo,
    individualHousing,
    condoHousing,
    business,
    stickerGlue,
    stickerNone,
    stickerNude,
    stickerScrew,
    member1,
    member5,
    member20,
    backBtn
  ]);
};
