import Http from 'src/services/Http';

import { STORAGE } from '../constants/Constant';
import { BasicUserInterface, UserInterface } from '../interfaces/model/UserInterface';
import DataApi from './DataApi';
import Socket from './Socket';

export type SocialMediaProvider = 'facebook' | 'google' | 'apple' | 'linkedin';

export const loginWithSocialMedia = async (provider: SocialMediaProvider, accessToken: string, userInfo = {}) => {
  return Http.post('users/social/signin', {
    provider,
    accessToken,
    userInfo
  }, {
    params: {
      debug: false
    }
  });
};

export const loginSuccess = async (user: UserInterface) => {
  const { refreshToken, accessToken } = user;

  if (refreshToken) {
    _storeRefreshToken(refreshToken);
    !!accessToken && await DataApi.setAuthorisation(accessToken, refreshToken);
  }

  const status = Socket.status();
  if (!status) Socket.connectUser(user.email || '');
  return user;

};

export const _storeRefreshToken = async (refreshToken: string) => {
  try {
    await localStorage.setItem(
      STORAGE.REFRESH_TOKEN,
      refreshToken
    );
  } catch (error) {
    console.log('error stock refresh token: ', error);
  }
};

export const logUserFromStorage = async () => {
  const refreshToken = await localStorage.getItem(STORAGE.REFRESH_TOKEN);
  if (!refreshToken) throw false;
  const { user, accessToken } = await refreshAccessToken(refreshToken);
  if (!accessToken) throw false;
  return await loginSuccess({ ...user, accessToken });
};

export const refreshAccessToken = async (refreshToken: string) => {
  return Http.get(`users/refresh-token/${refreshToken}`);
};

export const logout = (currentUser: any) => {
  _removeRefreshToken();
  Socket.disconnectUser(currentUser.email);
  DataApi.removeAuthorisation();
};

export const _removeRefreshToken = async () => {
  try {
    const refreshToken = await localStorage.getItem(STORAGE.REFRESH_TOKEN);
    if (refreshToken) {
      localStorage.removeItem(STORAGE.REFRESH_TOKEN);
    }
  } catch (error) {
    console.log('error stock token: ', error);
  }
};

export const resetPassword = async (password: string, token: string) => {
  return Http.put('users/reset/update-password', { password }, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

export const getBasicUserInfosByEmail = (email: string): Promise<BasicUserInterface> => {
  return Http.get(`users/email/${email}/check`);
};
