import './SimpleCard.scss';

import clsx from 'clsx';
import { memo } from 'react';

export interface SimpleCardInterface {
  value: string;
  title: string;
  describe: string;
  image: string;
  imgHeight: number;
  color: string;
  selected?: boolean;
  onSelect?: (value: string) => void;
}

const SimpleCard = ({
  value,
  title,
  describe,
  image,
  imgHeight,
  color,
  selected,
  onSelect = () => {},
}: SimpleCardInterface) => {

  const item = clsx(
    'simple-card',
    selected && 'selected'
  );

  const selectedColor = selected ? color : 'transparent';

  return (
    <div className={item} style={{borderColor: selectedColor}}>
      <div className='card' onClick={() => onSelect(value)}>
        <div className='color-overlay' style={{backgroundColor: selectedColor}}/>
        <div className='container'>
          <div className='img-container' >
            <img src={image} style={{height: `${imgHeight}px` || '100%'}}/>
          </div>
          <div className='details'>
            <p className='title' style={{color}}>{title}</p>
            <p className='describe'>{describe}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SimpleCard);
