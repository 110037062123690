import Interval from './Interval';

interface TimeoutOptionsInterfaces {
  current: number,
  intervalMessages: string[],
  timeoutMessageFailure: string
}

export const TM = {
  CALL_MESSAGE: {
    GROUP_NAME: 'call_message',
    INTERVAL_TIMEOUT_MESSAGES: [
      "connexion en cours...",
      'Encore un petit instant...',
      'Nous y sommes presque...',
      'Voila voila, ça arrive...'
    ],
    TIMEOUT_MESSAGE_FAILURE: 'Votre connexion internet semble trop faible...'
  },
  REQUEST: {
    GROUP_NAME: 'request',
    INTERVAL_TIMEOUT_MESSAGES: [
      'Encore un petit instant...',
      'Nous y sommes presque...',
      'Voila ça arrive...'
    ],
    TIMEOUT_MESSAGE_FAILURE: 'Votre connexion internet semble trop faible...'
  }
};

class Timeout {
  allTimeout: any = {};

  timeoutOptions = (messages: string[], errorMessage: string): TimeoutOptionsInterfaces => ({
    current: 0,
    intervalMessages: messages,
    timeoutMessageFailure: errorMessage
  });

  start = (
    groupName: string,
    messages: string[],
    errorMessage: string,
    interval: number,
    cbInInterval: (message: string) => void,
    cbOutInterval: (message: string) => void,
    executeInstantly?: boolean
  ) => {
    const instance = this.timeoutOptions(messages, errorMessage);
    if(executeInstantly) {
      cbInInterval(instance.intervalMessages[instance.current]);
      instance.current++;
    }
    const intervalId = Interval.make(() => {
      if (instance.current < instance.intervalMessages.length) {
        cbInInterval(instance.intervalMessages[instance.current]);
        instance.current++;
      } else {
        instance.current = 0;
        cbOutInterval(instance.timeoutMessageFailure);
        Interval.clear(intervalId);
      }
    }, interval);
    if (!this.allTimeout[groupName]) this.allTimeout[groupName] = new Set();
    this.allTimeout[groupName].add(intervalId);
  };

  clear = (id: any) => {
    Interval.clear(id);
  };

  clearByGroupName = (groupName: string) => {
    const group = this.allTimeout[groupName];
    console.log(`group delete ${groupName}`, group, this.allTimeout);
    group?.forEach((id: any) => this.clear(id));
    delete this.allTimeout[groupName];
  };

  clearAll = () => {
    Interval.clearAll();
  };
}

export default new Timeout();
