import './ColumnCard.scss';

import clsx from 'clsx';
import { memo } from 'react';

import { COLOR } from '../../constants/Constant';
export interface ColumnCardInterface {
  value: any;
  title: string;
  describe?: string;
  image?: string;
  color?: string;
  selected?: boolean;
  hasSeparator?: boolean;
  originalPrice?: number;
  reducedPrice?: number;
  priceExtension?: string;
  sentence?: string;
  onSelect?: (value: any) => void;
}

const ColumnCard = ({
  value,
  title,
  describe,
  image,
  color = COLOR.BLUE,
  selected,
  hasSeparator,
  originalPrice,
  reducedPrice,
  priceExtension,
  onSelect = () => {}
}: ColumnCardInterface) => {
  
  const parentClassName = clsx(
    'column-card-parent',
    selected && 'selected',
    hasSeparator && 'has-separator',
    title && describe && image 
      ? 'full-info'
      : (title && describe) || (title && image)
        ? 'partial-info' 
        : 'basic-info'
  );
    
  const selectedColor = selected ? color : 'transparent';

  return (
    <div
      className={parentClassName}
      style={{borderColor: selectedColor}}
      onClick={() => onSelect(value)}
    >
      {hasSeparator && <div className='column-card-separator' />}
      <div className='parent-color-overlay' style={{backgroundColor: selectedColor}}/>
      <div className='card'>
        <div className='color-overlay' style={{backgroundColor: selectedColor}}/>
        <div className='container'>
          {
            image && <div className='image-container'>
              <img src={image} />
            </div>
          }
          <div className='details'>
            <p className='title'>{title}</p>
            {describe && <p className='describe'>{describe}</p>}
          </div>
        </div>
      </div>
      {reducedPrice && <div className='extension-price-container'>
        {originalPrice && originalPrice > reducedPrice && <p className='original-price'>{originalPrice.toFixed(2)}€ {priceExtension}</p>}
        <p className='discounted-price'>{reducedPrice.toFixed(2)}€ {priceExtension}</p>
      </div>}
    </div>
  );
};

export default memo(ColumnCard);
