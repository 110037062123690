import './ChooseFerriteView.scss';

import ColumnCard, { ColumnCardInterface } from '../../../../components/ColumnCard';
import WarningBox from '../../../../components/WarningBox';
import { PaiementPayload, usePaymentProcess } from '../../../../hooks/PaymentProcessService';

const cards = [{
  title: 'Oui',
  value: true
}, {
  title: 'Non',
  value: false
}];

const ChooseFerriteView = () => {
  const { form, setForm } = usePaymentProcess();

  const _onSelect = (value: string) => setForm((f: PaiementPayload) => ({ ...f, withFerrite: value }));

  return (
    <>
      <div className='column-card-row'>
        {
          cards.map(({ value, title }: ColumnCardInterface, index: number) =>
            <ColumnCard
              key={index}
              value={value}
              title={title}
              onSelect={_onSelect}
              selected={form.withFerrite === value}
            />
          )
        }
      </div>

      <WarningBox
        message="L'anti-métal réduit considérablement la puissance du signal NFC. Si vous avez la possibilité de coller votre plaque NFC hors d'une surface métallique, nous vous conseillons de le faire. À l'inverse, vous devez ajouter cette option." 
      />
    </>
  );
};

export default ChooseFerriteView;
