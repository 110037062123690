import './QuantitySelector.scss';

import React, { useState } from 'react';

interface QuantitySelectorInterface {
  value: number;
  onUpdate: (quantity: number) => void;
}

const QuantitySelector = ({
  value,
  onUpdate
}: QuantitySelectorInterface) => {
  const increment = () => {
    const newQuantity = value + 1;
    onUpdate(newQuantity);
  };

  const decrement = () => {
    const newQuantity = value > 1 ? value - 1 : 1;
    onUpdate(newQuantity);
  };

  return (
    <div className='quantity-selector-container'>
      <button className="min" onClick={decrement} disabled={value <= 1}><span>-</span></button>
      <input
        type="text"
        value={value}
        readOnly
      />
      <button className="max" onClick={increment} disabled={value >= 5}><span>+</span></button>
    </div>
  );
};

export default QuantitySelector;
