import ChooseStickerViewV1 from './Views/ChooseStickerView';
import PaymentView from './Views/PaymentView';

export interface ViewsInterface {
  step?: number;
  hidden?: boolean;
  component: any;
  shortTitle: string;
  title: string;
  describe: string;
  inputs: any;
  slug: string;
  buttonEventClass: string;
  wrapperSize?: 'sm' | 'md' | 'lg';
  isValidate?: (obj: any) => boolean;
  getOldPrice?: (obj: any) => number;
  getPrice?: (obj: any) => number;
}

export const VIEWS_V1: ViewsInterface[] = [
  {
    component: ChooseStickerViewV1,
    shortTitle: "Type de plaque NFC",
    slug: 'stickerChoice',
    buttonEventClass: 'payWithStripe',
    title: "Quel [type de plaque NFC] souhaitez-vous installer ?",
    describe: "Le type de plaque NFC dépend de la surface sur laquelle vous souhaitez l'installer.",
    inputs: {
      stickerType: {
        type: "string",
        defaultValue: null,
        shouldBe: ['glue', 'screw', 'nude']
      },
    },
    wrapperSize: 'lg',
    isValidate: (obj: any) => {
      return !!obj.stickerType;
    },
    getOldPrice: (obj: any) => {
      return 59 * obj.quantity;
    },
    getPrice: (obj: any) => {
      return obj.quantity === 1 ? 59 : (59 * obj.quantity) * (1 - 0.1695);
    }
  },
  {
    component: PaymentView,
    shortTitle: 'Récapitulatif',
    slug: 'resume',
    buttonEventClass: 'payWithStripe',
    title: 'Récapitulatif de la [commande]',
    describe: 'Si vous avez la moindre question, n’hésitez pas à nous contacter par email : [hello@cleancall.fr]',
    wrapperSize: 'sm',
    inputs: {},
    isValidate: () => true
  }
];
