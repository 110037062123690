import { PaiementPayload } from '../hooks/PaymentProcessService';
import Http from './Http';

export type ProviderType = 'stripe' | 'paypal';

export interface GeneratedLinkInterface {
  id: string,
  url: string
}

export const getCheckoutUrl = async (provider: ProviderType, payload: PaiementPayload, housingId?: string): Promise<GeneratedLinkInterface> => {
  return Http.post(`checkout/generate/link/${provider}`, payload, housingId && { params: { housingId } });
};

export const getCheckoutUrlAsAnonymous = async (provider: ProviderType, payload: PaiementPayload, housingId?: string): Promise<GeneratedLinkInterface> => {
  return Http.post(`checkout/generate/link/anonymous/${provider}`, payload, housingId && { params: { housingId } });
};

export const getStripeSessionInfo = async (sessionId: string): Promise<any> => {
  return Http.get(`checkout/session/${sessionId}`);
};
