// Analytics.ts
interface UserData {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  address?: AddressData;
}

interface AddressData {
  city?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  countryCode?: string;
}

interface OrderData {
  value: string;
  currency?: string;
  sessionId: string;
  productName: string;
}

class Analytics {
  public push(eventName: string, data?: Record<string, any>) {
    const event = {
      event: eventName,
      ...data,
    };
    // console.log('event', event);
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(event);
  }

  public initiateCheckout(userData?: UserData, sessionId?: string) {
    this.push('trigger_initiateCheckout', {
      user_data: {
        address: {
          first_name: userData?.firstName?.toLowerCase(),
          last_name: userData?.lastName?.toLowerCase()
        },
        email_address: userData?.email?.toLowerCase(),
      },
      session_id: sessionId
    });
  }

  public purchase(userData: UserData, order: OrderData) {
    this.push('trigger_purchase', {
      user_data: {
        address: {
          first_name: userData.firstName?.toLowerCase(),
          last_name: userData.lastName?.toLowerCase(),
          city: userData.address?.city?.toLowerCase(),
          region: userData.address?.region?.toLowerCase(),
          postal_code: userData.address?.postalCode?.toLowerCase(),
          country: userData.address?.country?.toLowerCase(),
          country_code: userData.address?.countryCode?.toLowerCase(),
        },
        email_address: userData.email?.toLowerCase(),
        phone_number: userData.phone,
      },
      value: order.value,
      currency: order.currency || 'EUR',
      content_name: order.productName?.toLowerCase(),
      session_id: order.sessionId
    });
  }
}

export default new Analytics();
